document.addEventListener('DOMContentLoaded', () => {
  const headerHeight = document.querySelector('header').offsetHeight;

  const scrollToElement = (element) => {
    window.scrollTo({
      top: element.offsetTop - headerHeight,
      behavior: 'smooth',
    });
  };

  const hash = window.location.hash;
  if (hash) {
    const targetElement = document.querySelector(hash);
    if (targetElement) {
      setTimeout(() => {
        scrollToElement(targetElement);
      }, 0);
    }
  }

  const navToggle = document.getElementById('navToggle');
  const navMenu = document.getElementById('navMenu');

  navToggle.addEventListener('click', () => {
    navMenu.classList.toggle('flex');
    navMenu.classList.toggle('hidden');
  });

  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      const targetId = this.getAttribute('href');
      const targetElement = document.querySelector(targetId);

      if (targetElement) {
        scrollToElement(targetElement);
      }
    });
  });

  const sections = document.querySelectorAll('section');
  const navLinks = document.querySelectorAll('nav a[href^="#"]');

  const sectionInView = (elem, offset = 0) => {
    const elementTop = elem.getBoundingClientRect().top;
    return elementTop <= offset;
  };

  const updateNavLinks = () => {
    let currentSectionId = '';

    sections.forEach((section) => {
      if (sectionInView(section, window.innerHeight / 2)) {
        currentSectionId = section.id;
      }
    });

    navLinks.forEach((link) => {
      link.classList.remove('text-cyan-600');
      if (link.getAttribute('href') === `#${currentSectionId}`) {
        link.classList.add('text-cyan-600');
      }
    });
  };

  window.addEventListener('scroll', updateNavLinks);

  const accordions = document.querySelectorAll('.accordion-toggle');

  const closeAllAccordions = () => {
    accordions.forEach((accordion) => {
      accordion.classList.remove('active');
      const content = accordion.nextElementSibling;
      content.style.maxHeight = null;
    });
  };

  accordions.forEach((accordion) => {
    accordion.addEventListener('click', function () {
      if (!this.classList.contains('active')) {
        if (window.innerWidth >= 768) {
          closeAllAccordions();
        }

        this.classList.add('active');
        const content = this.nextElementSibling;
        content.style.maxHeight = content.scrollHeight + 'px';
      } else {
        this.classList.remove('active');
        this.nextElementSibling.style.maxHeight = null;
      }
    });
  });
});

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper.css';
import 'swiper/modules/navigation.css';
import 'swiper/modules/pagination.css';

const swiper = new Swiper('.swiper', {
  modules: [Navigation, Pagination],
  navigation: {
    prevEl: '.swiper .swiper-button-prev',
    nextEl: '.swiper .swiper-button-next',
  },
  pagination: {
    el: '.swiper .swiper-pagination',
  },
});
